import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let DealflowIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 500 500"}>
		<title>Dealflow</title>
		<g>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				fill="#41ACFF"
				d="M78.61,318.44v0.02c3.44,80.73,34.06,132.72,91.9,156.03V266.01
		V162.44C112.67,185.74,82.05,237.73,78.61,318.44z"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				fill="#008FFE"
				d="M203.72,176.35v-21.49c0-30.67,15.42-46.05,46.17-46.05h46.06
		v376.68c-14.35,2.25-29.94,3.31-46.75,3.17c-16.33-0.26-31.49-1.76-45.48-4.49V302.03V176.35z"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				fill="#007CDC"
				d="M329.16,78.87V57.38c0-30.67,15.39-46.05,46.17-46.05h46.06
		v313.25c0,80.71-30.74,131.67-92.23,152.83V167.32V78.87z"
			/>
		</g>
	</SvgIcon>
);

DealflowIcon = memo(DealflowIcon);
DealflowIcon.displayName = "DealflowIcon";
DealflowIcon.muiName = "DealflowIcon";

export default DealflowIcon;
