import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TutorsIcon = (props) => (
	<SvgIcon {...props} viewBox={"-0.202 -0.049 498 398"}>
		<title>Tutors</title>

		<path
			fill="#FFBB00"
			d="M0,218.097c0,0,59.434-102.912,60.568-106.567c0.486-1.572,0.647-2.927,0.532-4.281
	c-0.07-1.018,424.302,182.499,374.668,182.499H372.98l-62.186,108.154H207.456c-96.585,0-103.34-0.186-104.498-3.264L0,218.097z"
		/>
		<path
			fill="#00A1F2"
			d="M270.047,288.396c-103.351-0.59-105.677-0.777-106.833-4.455C162.635,281.812,1.933,2.512,1.933,1.747
	C1.933,0.775,75.713,0,166.313,0C306.155,0,331.065,0.394,333.39,2.707c1.54,1.552,2.697,3.473,2.697,4.061
	c0,0.764,160.909,279.291,161.476,280.654c0.776,1.736-11.766,2.131-60.451,1.957C403.315,288.96,328.185,288.58,270.047,288.396
	L270.047,288.396z"
		/>
		<path
			fill="#F2625A"
			d="M163.214,285.548c-0.579-2.129-101.595-175.776-101.595-175.776h103.908
	c101.037,0.186,104.313,0.382,107.411,4.05c1.735,1.932,99.856,172.871,99.856,174.621c0,0.588-46.937,0.971-104.114,0.971
	C166.313,289.414,164.37,289.414,163.214,285.548L163.214,285.548z"
		/>
	</SvgIcon>
);

TutorsIcon = memo(TutorsIcon);
TutorsIcon.displayName = "TutorsIcon";
TutorsIcon.muiName = "TutorsIcon";

export default TutorsIcon;
