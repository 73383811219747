import * as queries from "./query";

import ProviderService from "@services/Provider";
import ClientService from "@services/Client";
import AccountService from "@services/Account";
import MessageService from "@services/Message";

export default {
	...new ProviderService({ queries }),
	...new ClientService({ queries }),
	...new AccountService({ queries }),
	...new MessageService({ queries }),
};
