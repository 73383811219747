import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let BetterCareIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 112.95 112.95"}>
		<title>Better care</title>
		<g>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				fill="#00A1F2"
				d="M31.59,81.76c0.05,0.73,0.51,0.44,0.69,0.3
		c2.93-2.43,10.62-4.58,16.91-4.5h16.97v0.03l0.36,0c5.61-0.07,9.26-2.11,11.44-4.95c2.4-3.12,3.39-7.35,3.39-11.3h0.03l0-0.32
		c0-0.18-0.02-0.55-0.06-1.14c-0.22-3.45-1.3-6.21-3.28-7.95c-2.2-1.94-5.78-3.02-10.87-2.95c-2.84,0.03-9.73-0.03-14.57,3.28
		c-2.24,1.53-2.47,0.23-2.17-1.11c1.64-7.26,5.01-9.02,7.38-14.27c0.65-1.1,1.13-2.36,1.42-3.7c0.55-2.56,0.41-5.32-0.51-7.79
		c-0.86-2.31-2.45-4.4-4.87-5.84c-1.27-0.75-2.79-1.35-4.56-1.72c-1.07-0.23-2.2-0.34-3.36-0.34c-3.28,0-6.44,0.98-8.92,2.85
		c-2.42,1.82-4.27,4.56-5.04,8.15c-0.25,1.18-0.39,2.52-0.39,4V81.76L31.59,81.76z M66.17,93.77H47.46c-8.83,0-25,5.22-30.51,17.16
		c-0.6,1.29-1.56,0.7-1.56-0.16V85.67v-53.2c0-2.55,0.27-5,0.77-7.35c1.64-7.65,5.75-13.63,11.18-17.71
		c5.36-4.03,11.94-6.14,18.61-6.14c2.19,0,4.45,0.24,6.72,0.72c3.57,0.76,6.71,2.01,9.43,3.62c5.83,3.46,9.68,8.52,11.77,14.13
		c1.66,4.46,2.18,9.24,1.68,13.8c5.36,1.04,9.75,3.18,13.2,6.22c5.37,4.73,8.22,11.31,8.72,19.1c0.03,0.43,0.05,1.1,0.06,1.97v0.06
		l0,0.42l0.03,0.03c0,7.07-1.96,14.89-6.75,21.11c-4.99,6.5-12.77,11.13-24.11,11.29h-0.06l-0.45,0L66.17,93.77L66.17,93.77z
		 M81.38,61.01v-0.06V61.01z"
			/>
		</g>
	</SvgIcon>
);

BetterCareIcon = memo(BetterCareIcon);
BetterCareIcon.displayName = "BetterCareIcon";
BetterCareIcon.muiName = "BetterCareIcon";

export default BetterCareIcon;
