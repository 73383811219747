import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let LawnmastersIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 500 500"}>
		<title>Lawn Masters</title>

		<circle fill="#59BD76" cx="250" cy="250" r="225" />
		<path
			fill="#FFFFFF"
			d="M334.76,274.84c0.42,0.16,0.65,0.62,0.53,1.05l-20.23,70.78c-0.17,0.57-0.13,1.18,0.12,1.73
	c6.39,14.39,12.33,29.49,17.84,45.31c0.18,0.53,0.43,0.56,0.75,0.12c12.05-16.24,19.63-33.91,22.74-53.02
	c5.81-35.76-5.29-71.86-24.04-102.73c-18.62-30.65-44.09-57.12-72.29-79.29c-34.71-27.29-73.9-49.89-117.55-67.8
	c-0.71-0.29-0.89-0.09-0.54,0.6c11.95,23.46,20.63,49.25,27.17,75.55c3.82,15.33,6.8,29.11,8.93,41.33
	c4.17,23.9,8.32,48.16,12.46,72.79c1.61,9.58,4.02,20.06,7.25,31.42c4.92,17.34,11.88,33.84,23.03,47.81
	c10.1,12.64,23.84,22.94,38.04,30.09c15.84,7.98,32.75,14.17,50.73,18.56c0.19,0.04,0.38-0.08,0.42-0.27c0.01-0.05,0.01-0.1,0-0.14
	c-2.31-11.31-5.19-22.43-8.64-33.38c-0.1-0.31-0.32-0.56-0.61-0.71l-81.15-40.07c-0.33-0.16-0.47-0.55-0.34-0.89l1.22-3.16
	c0.18-0.45,0.5-0.6,0.95-0.45l72.11,23.88c0.45,0.15,0.59,0,0.42-0.45c-6.48-17.57-13.67-34.78-21.57-51.65
	c-0.24-0.51-0.64-0.92-1.14-1.16l-51.15-24.84c-0.52-0.25-0.69-0.65-0.51-1.2l0.88-2.79c0.16-0.51,0.7-0.8,1.21-0.65l41.22,12.15
	c0.78,0.24,0.98-0.01,0.62-0.74c-19.75-39.28-40.7-78.08-62.87-116.41c-0.22-0.38-0.14-0.7,0.22-0.95l2.83-1.92
	c0.34-0.23,0.8-0.15,1.03,0.19c20.24,28.23,39.54,57.02,57.89,86.36c0.08,0.14,0.25,0.18,0.39,0.09c0.06-0.04,0.1-0.09,0.13-0.15
	l12.28-34.61c0.1-0.28,0.4-0.45,0.69-0.38l3.21,0.82c0.56,0.15,0.8,0.51,0.72,1.09l-7.22,48.86c-0.1,0.68,0.03,1.36,0.38,1.95
	c11.88,20.04,22.81,40.43,32.79,61.14c0.06,0.14,0.22,0.2,0.36,0.13c0.06-0.03,0.12-0.08,0.14-0.15l24.06-54.54
	c0.18-0.4,0.63-0.59,1.05-0.43L334.76,274.84z"
		/>
	</SvgIcon>
);

LawnmastersIcon = memo(LawnmastersIcon);
LawnmastersIcon.displayName = "LawnmastersIcon";
LawnmastersIcon.muiName = "LawnmastersIcon";

export default LawnmastersIcon;
