import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TaloIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 32 32"}>
		<title>Talo</title>
		<g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#222222"
				d="M8.27,16c0-3.39,2.19-6.28,5.23-7.32V1.43
		C6.53,2.61,1.22,8.69,1.22,16c0,8.16,6.62,14.78,14.78,14.78c7.31,0,13.39-5.31,14.57-12.29h-7.26c-1.04,3.04-3.92,5.23-7.31,5.23
		C11.73,23.73,8.27,20.27,8.27,16"
			/>
			<polygon
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#33CC66"
				points="16,1.22 16,8.27 23.73,8.27 23.73,16 30.78,16 
		30.78,8.27 30.78,1.22 23.73,1.22 	"
			/>
		</g>
	</SvgIcon>
);

TaloIcon = memo(TaloIcon);
TaloIcon.displayName = "TaloIcon";
TaloIcon.muiName = "TaloIcon";

export default TaloIcon;
