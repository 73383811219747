import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let VetyIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 500 500"}>
		<title>Vety</title>

		<g>
			<path
				fill="#00A1F2"
				d="M144.02,367.24c-19.24,19.6-57.84,14.75-86.22-10.84c-28.38-25.58-35.8-62.22-16.57-81.82
		c19.23-19.61,57.84-14.75,86.22,10.84C155.83,310.99,163.25,347.63,144.02,367.24z"
			/>
			<path
				fill="#00A1F2"
				d="M68.95,207.64c27.65,34.19,71,45.91,96.81,26.18c25.82-19.74,24.33-63.43-3.31-97.61
		c-27.67-34.18-71.01-45.9-96.82-26.17C39.81,129.77,41.29,173.46,68.95,207.64z"
			/>
			<path
				fill="#00A1F2"
				d="M374.21,83.59c26.71-6.47,54.57,18.89,62.26,56.64c7.68,37.76-7.74,73.61-34.44,80.09
		c-26.72,6.47-54.58-18.88-62.26-56.64C332.08,125.92,347.52,90.06,374.21,83.59z"
			/>
			<path
				fill="#00A1F2"
				d="M218.01,16.31c30.17-12.08,67.28,11.99,82.87,53.75c15.59,41.77,3.78,85.41-26.4,97.49
		c-30.17,12.08-67.28-11.98-82.88-53.75C176.01,72.04,187.83,28.39,218.01,16.31z"
			/>
			<path
				fill="#00A1F2"
				d="M232.94,209.72c-38.24,21.2-37.29,59.55-41.67,100.63c-4.16,39.11-13.46,55.93-19.48,75.19
		c-5.39,17.28-17.96,76.41,24.5,97.14c49.97,24.4,89.4-44.48,138.34-71.62c52.88-29.33,128.76-31.55,133.81-75.39
		c4.63-40.04-27.49-68.76-69.34-76.82c-20.59-3.97-46.9-15.81-61.69-21.81C306.42,224.46,273.88,187.02,232.94,209.72z"
			/>
		</g>
	</SvgIcon>
);

VetyIcon = memo(VetyIcon);
VetyIcon.displayName = "VetyIcon";
VetyIcon.muiName = "VetyIcon";

export default VetyIcon;
