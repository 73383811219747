export default (theme) => ({
	root: {
		flexGrow: 1,
	},
	AppBar: {
		color: theme.palette.secondary.main,
		backgroundColor: "#FFF",
		borderBottom: "1px solid rgba(0,0,0,0.1)",
		boxShadow: "none",
		zIndex: theme.zIndex.drawer + 1,
	},
	Toolbar: {
		paddingLeft: 16,
		paddingRight: 16,
		[theme.breakpoints.up("sm")]: {
			minHeight: 72,
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: 64,
		},
	},
	ToolbarNoSearch: {
		paddingLeft: 16,
		paddingRight: 16,
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
			minHeight: 64,
		},
		[theme.breakpoints.up("sm")]: {
			minHeight: 72,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		//flexGrow: 1,
		marginRight: 20,
	},
	iconWrap: {
		margin: 0,
	},
	list: {
		width: 250,
	},
	listIcon: {
		minWidth: "40px",
	},
	listItem: {
		fontWeight: 600,
		fontSize: 16,
		lineHeight: 1.5,
	},
	profile: {
		"borderRadius": 8,
		"&:hover": {
			background: "rgba(0, 35, 75, 0.04)",
		},
	},
	adminListItemText: {
		fontWeight: 600,
	},
	adminListItemIcon: {
		minWidth: 40,
		color: theme.palette.secondary.main,
	},
	popperMenu: {
		width: 240,
	},
	inboxBadge: {
		position: "relative",
	},
	inboxBadgeAnchor: {
		top: 10,
		right: 4,
	},
	listBadge: {
		right: 12,
	},
	buttonMargin: {
		marginRight: 12,
	},
});
