import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let HomeGuideIcon = (props) => (
	<SvgIcon {...props} viewBox={"-39.688 -2.762 500 500"}>
		<title>HomeGuide</title>
		<path
			fill="#0074bb"
			d="M9.733 169.648L192.685 14.913 210.313 0l17.64 14.913 182.949 154.735 9.724 8.236v12.609 276.622 27.36h-27.36H27.374 0v-27.36-276.621-12.609l9.733-8.237h0zm200.58-98.138L54.721 203.105v236.646h311.184V203.105L210.313 71.51h0z"
		/>
		<path
			d="M126.688 214.248h67.981v67.976h-67.981v-67.976zm99.27 0h67.979v67.976h-67.979v-67.976zm-99.27 96.912h67.981v67.985h-67.981V311.16zm99.27 0h67.979v67.985h-67.979V311.16z"
			fillRule="evenodd"
			fill="#009cde"
		/>
	</SvgIcon>
);

HomeGuideIcon = memo(HomeGuideIcon);
HomeGuideIcon.displayName = "HomeGuideIcon";
HomeGuideIcon.muiName = "HomeGuideIcon";

export default HomeGuideIcon;
