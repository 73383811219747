import React from "react";
import PropTypes from "prop-types";

import TutorsIcon from "@components/Global/Custom/Logo/tutors/TutorsIcon";
import ThervoIcon from "@components/Global/Custom/Logo/thervo/ThervoIcon";
import HomeGuideIcon from "@components/Global/Custom/Logo/homeguide/HomeGuideIcon";
import LessonsIcon from "@components/Global/Custom/Logo/lessons/LessonsIcon";
import FashIcon from "@components/Global/Custom/Logo/fash/FashIcon";
import BetterCareIcon from "@components/Global/Custom/Logo/bettercare/BetterCareIcon";
import TrustedCareIcon from "@components/Global/Custom/Logo/trustedcare/TrustedCareIcon";
import DealflowIcon from "@components/Global/Custom/Logo/dealflow/DealflowIcon";
import RVgoIcon from "@components/Global/Custom/Logo/rvgo/RVgoIcon";
import VetyIcon from "@components/Global/Custom/Logo/vety/VetyIcon";
import TaloIcon from "@components/Global/Custom/Logo/talo/TaloIcon";
import LawfulIcon from "@components/Global/Custom/Logo/lawful/LawfulIcon";
import LawnmastersIcon from "@components/Global/Custom/Logo/lawnmasters/LawnmastersIcon";

const { SITE_NAME } = process.env;

const LogoIcon = ({ width, height, filter }) => {
	return (
		<>
			{SITE_NAME === "tutors" && (
				<TutorsIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "homeguide" && (
				<HomeGuideIcon
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "lessons" && (
				<LessonsIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "thervo" && (
				<ThervoIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "fash" && (
				<FashIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "bettercare" && (
				<BetterCareIcon
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "trustedcare" && (
				<TrustedCareIcon
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "dealflow" && (
				<DealflowIcon
					style={{ width: width, height: height, filter: filter }}
				/>
			)}

			{SITE_NAME === "rvgo" && (
				<RVgoIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "vety" && (
				<VetyIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "talo" && (
				<TaloIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "lawful" && (
				<LawfulIcon style={{ width: width, height: height, filter: filter }} />
			)}

			{SITE_NAME === "lawnmasters" && (
				<LawnmastersIcon
					style={{ width: width, height: height, filter: filter }}
				/>
			)}
		</>
	);
};

LogoIcon.propTypes = {};
export default LogoIcon;
