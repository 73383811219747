import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { observer } from "mobx-react";
import withStyles from "@framework/src/libs/withStyles";
import withConstants from "@framework/src/libs/withConstants";
import { compose } from "recompose";

import useConstants from "@hooks/useConstants";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import { constants, stores, styles } from "./.config";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Avatar from "@material-ui/core/Avatar";

import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Drawer from "@material-ui/core/Drawer";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DashboardIconOutlined from "@material-ui/icons/DashboardOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";

import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

import Badge from "@material-ui/core/Badge";
import withStores from "@libs/framework/src/libs/withStores";
import LogoIcon from "@components/Global/Custom/Logo/LogoIcon";
import Logo from "@components/Global/Custom/Logo/Logo";
import BackgroundNavManagement from "@components/Dashboard/Client/Shared/Background/BackgroundNavManagement";

import BackgroundNavManagementPro from "@components/Dashboard/Pro/Shared/Background/BackgroundNavManagement";

const { SITE_NAME, BRAND_NAME, LINK_HELP, ENV } = process.env;

const Header = compose(
	withStyles(styles),
	withConstants(constants),
	withStores(stores, { setGlobal: true }),
	observer
)(({ id, searchBar, fixed, ...props }) => {
	const classes = useStyles("Header");
	const { headerStore: store, accountStore } = useStores();
	const { authenticated } = accountStore.account;

	const {
		logoWidth,
		logoHeight,
		logoWidthMobile,
		logoHeightMobile,
		iconHeight,
		iconWidth,
		quickLinkServices,
		proJoinLanguage,
	} = useConstants("HeaderViewModel");

	const anchor = "left";

	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const [expand, setExpand] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClick = () => setExpand(!expand);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	useEffect(() => {
		if (authenticated) store.init(accountStore.account.type);
	}, []);

	return (
		<div id={id}>
			<div className={classes.root}>
				<AppBar
					position={fixed ? "fixed" : "static"}
					className={classes.AppBar}
				>
					<Toolbar
						className={clsx({
							[classes.Toolbar]: searchBar,
							[classes.ToolbarNoSearch]: !searchBar,
						})}
					>
						{!searchBar ? (
							<React.Fragment>
								<Box
									display={{ xs: "block", md: "none" }}
									position={"absolute"}
									left={16}
								>
									<IconButton
										edge="start"
										color="inherit"
										aria-label="menu"
										onClick={toggleDrawer(anchor, true)}
									>
										<MenuIcon />
									</IconButton>
								</Box>

								<Box display={{ xs: "block", md: "none" }}>
									<Box
										display={"flex"}
										alignItems={"center"}
										component={"a"}
										href="/"
										alt={BRAND_NAME}
										aria-label={BRAND_NAME}
									>
										<Logo width={logoWidthMobile} height={logoHeightMobile} />
									</Box>
								</Box>

								<Box
									display={{ xs: "none", md: "flex" }}
									component={"a"}
									href="/"
									className={classes.title}
									alt={BRAND_NAME}
									aria-label={BRAND_NAME}
								>
									<Logo width={logoWidth} height={logoHeight} />
								</Box>

								{(ENV === "stage" || ENV === "development") &&
									!store.isProviderType(accountStore.account.type) && (
										<>
											<Box
												display={{ xs: "block", sm: "none" }}
												position={"absolute"}
												right={16}
											>
												<IconButton
													color="secondary"
													href="/pros/booking/cart"
													target="_blank"
												>
													<Badge badgeContent={4} color="error">
														<ShoppingCartOutlinedIcon />
													</Badge>
												</IconButton>
											</Box>
										</>
									)}
							</React.Fragment>
						) : (
							<React.Fragment>
								{authenticated ? (
									<React.Fragment>
										<Box display={{ xs: "block", md: "none" }}>
											<IconButton
												edge="start"
												color="inherit"
												aria-label="menu"
												onClick={toggleDrawer(anchor, true)}
												style={{ borderRadius: 8 }}
											>
												<Box
													display={"flex"}
													alignItems={"center"}
													className={classes.iconWrap}
												>
													<LogoIcon width="inherit" height={iconHeight} />

													<ExpandMoreOutlinedIcon style={{ marginLeft: 4 }} />
												</Box>
											</IconButton>
										</Box>
										<Box display={{ xs: "none", md: "block" }}>
											<IconButton
												href="/"
												edge="start"
												color="inherit"
												aria-label="menu"
												style={{ marginRight: 4, borderRadius: 8 }}
											>
												<Box
													display={"flex"}
													alignItems={"center"}
													className={classes.iconWrap}
												>
													<LogoIcon width="inherit" height={iconHeight} />
												</Box>
											</IconButton>
										</Box>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Box display={{ xs: "block", md: "none" }}>
											<IconButton
												edge="start"
												color="inherit"
												aria-label="menu"
												onClick={toggleDrawer(anchor, true)}
												style={{ borderRadius: 8 }}
											>
												<Box
													display={"flex"}
													alignItems={"center"}
													className={classes.iconWrap}
												>
													<LogoIcon width="inherit" height={iconHeight} />

													<ExpandMoreOutlinedIcon style={{ marginLeft: 4 }} />
												</Box>
											</IconButton>
										</Box>
										<Box
											display={{ xs: "none", md: "flex" }}
											component={"a"}
											href="/"
											className={classes.title}
											alt={BRAND_NAME}
											aria-label={BRAND_NAME}
										>
											<Logo width={logoWidth} height={logoHeight} />
										</Box>
									</React.Fragment>
								)}
							</React.Fragment>
						)}

						{props.children}

						{(ENV === "stage" || ENV === "development") &&
							searchBar &&
							!store.isProviderType(accountStore.account.type) && (
								<>
									<Box
										display={{ xs: "block", sm: "block", md: "none" }}
										flexGrow={1}
										pl={2}
									>
										{/* <Box px={4} height={36}>
										<Divider orientation="vertical" light />
									</Box> */}
										<Tooltip
											enterDelay={400}
											title="My cart"
											arrow
											TransitionComponent={Zoom}
											placement={"bottom"}
										>
											<IconButton
												color="secondary"
												href="/pros/booking/cart"
												target="_blank"
											>
												<Badge badgeContent={4} color="error">
													<ShoppingCartOutlinedIcon />
												</Badge>
											</IconButton>
										</Tooltip>
									</Box>
								</>
							)}

						<Box
							display={{ xs: "none", sm: "none", md: "block" }}
							flexGrow={1}
						/>

						{["dealflow"].includes(SITE_NAME) ? (
							<>Coming Q3 2024</>
						) : authenticated ? (
							<React.Fragment>
								<Box
									display={{ xs: "none", sm: "none", md: "flex" }}
									alignItems={"center"}
								>
									{store.isClientType(accountStore.account.type) && (
										<>
											<div className={classes.buttonMargin}>
												<Button color={"secondary"} href="/client/projects">
													Requests
												</Button>
											</div>

											<div className={classes.buttonMargin}>
												<Button color={"secondary"} href="/client/inbox">
													Inbox{" "}
													{!!store.inboxCount && (
														<Badge
															badgeContent={store.inboxCount}
															color="primary"
															overlap="rectangular"
															classes={{
																anchorOriginTopRightRectangular:
																	classes.inboxBadgeAnchor,
																badge: classes.inboxBadge,
															}}
														/>
													)}
												</Button>
											</div>
										</>
									)}
									{store.isProviderType(accountStore.account.type) && (
										<div className={classes.buttonMargin}>
											<Button
												color={"primary"}
												variant={"contained"}
												disableElevation
												href="/pros"
												endIcon={<ChevronRightIcon />}
											>
												{`Pro dashboard`}
											</Button>
										</div>
									)}
									<div>
										<ButtonBase
											ref={anchorRef}
											aria-controls={open ? "menu-list-grow" : undefined}
											aria-haspopup="true"
											onClick={handleToggle}
											className={classes.profile}
										>
											<Box display={"flex"} alignItems={"center"} p={"4px 8px"}>
												<Avatar
													alt={"First name"}
													src={store.image}
													imgProps={{ loading: "lazy", decoding: "async" }}
													style={{
														width: 32,
														height: 32,
													}}
												/>
												{store.isClientType(accountStore.account.type) &&
													!!store.client && (
														<Box ml={2} mr={1} fontSize={14} fontWeight={600}>
															{store.client.first_name.ucFirst()}
														</Box>
													)}

												<ExpandMoreOutlinedIcon />
											</Box>
										</ButtonBase>
									</div>
									{(ENV === "stage" || ENV === "development") &&
										store.isClientType(accountStore.account.type) && (
											<>
												<Box px={3} height={36}>
													<Divider orientation="vertical" light />
												</Box>
												<Tooltip
													enterDelay={400}
													title="My cart"
													arrow
													TransitionComponent={Zoom}
													placement={"bottom"}
												>
													<IconButton
														color="secondary"
														href="/pros/booking/cart"
														target="_blank"
													>
														<Badge badgeContent={4} color="error">
															<ShoppingCartOutlinedIcon />
														</Badge>
													</IconButton>
												</Tooltip>
											</>
										)}
								</Box>

								<Popper
									open={open}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
									disablePortal
									style={{ zIndex: 1500 }}
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin:
													placement === "bottom"
														? "center top"
														: "center bottom",
											}}
										>
											<Paper elevation={6} className={classes.popperMenu}>
												<ClickAwayListener onClickAway={handleClose}>
													<div>
														{ENV === "stage" ||
															(ENV === "development" ? (
																<>
																	{store.isClientType(
																		accountStore.account.type
																	) ? (
																		<BackgroundNavManagement />
																	) : (
																		<BackgroundNavManagementPro open />
																	)}
																	<Divider light />
																	<MenuItem
																		onClick={() => accountStore.logout()}
																	>
																		<ListItemIcon
																			className={classes.adminListItemIcon}
																		>
																			<ExitToAppOutlinedIcon />
																		</ListItemIcon>

																		<ListItemText
																			className={classes.adminListItemText}
																			disableTypography
																			primary={"Sign out"}
																		/>
																	</MenuItem>
																</>
															) : (
																<MenuList
																	autoFocusItem={open}
																	id="menu-list-grow"
																	onKeyDown={handleListKeyDown}
																>
																	<MenuItem
																		component={"a"}
																		href={store.redirectToAccount(
																			accountStore.account.type
																		)}
																		onClick={handleClose}
																	>
																		<ListItemIcon
																			className={classes.adminListItemIcon}
																		>
																			<AccountCircleOutlinedIcon />
																		</ListItemIcon>

																		<ListItemText
																			className={classes.adminListItemText}
																			disableTypography
																			primary={"Account"}
																		/>
																	</MenuItem>
																	{/* <MenuItem
															divider
															onClick={() => accountStore.logout()}
														>
															<ListItemIcon
																className={classes.adminListItemIcon}
															>
																<ToggleOffOutlinedIcon />
															</ListItemIcon>

															<ListItemText
																className={classes.adminListItemText}
																disableTypography
																primary={"Switch account"}
															/>
														</MenuItem> */}
																	<MenuItem
																		component="a"
																		href={LINK_HELP}
																		target="_blank"
																	>
																		<ListItemIcon
																			className={classes.adminListItemIcon}
																		>
																			<HelpOutlineIcon />
																		</ListItemIcon>

																		<ListItemText
																			className={classes.adminListItemText}
																			disableTypography
																			primary={"Help"}
																		/>
																	</MenuItem>
																	<MenuItem
																		onClick={() => accountStore.logout()}
																	>
																		<ListItemIcon
																			className={classes.adminListItemIcon}
																		>
																			<ExitToAppOutlinedIcon />
																		</ListItemIcon>

																		<ListItemText
																			className={classes.adminListItemText}
																			disableTypography
																			primary={"Sign out"}
																		/>
																	</MenuItem>
																</MenuList>
															))}
													</div>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Box display={{ xs: "none", md: "flex" }} alignItems={"center"}>
									<Box ml={6} className={classes.buttonMargin}>
										<Button color={"secondary"} href="/pro">
											{proJoinLanguage}
										</Button>
									</Box>
									<div className={classes.buttonMargin}>
										<Button color={"secondary"} href="/join">
											Sign up
										</Button>
									</div>
									<div>
										<Button color={"secondary"} href="/login">
											Log in
										</Button>
									</div>
								</Box>
							</React.Fragment>
						)}
					</Toolbar>
				</AppBar>
				{fixed && <Box height={{ xs: 57, sm: 65 }} />}
			</div>

			<Drawer
				anchor={anchor}
				open={state[anchor]}
				onClose={toggleDrawer(anchor, false)}
			>
				<div className={classes.list}>
					<List>
						{authenticated ? (
							<>
								{ENV === "stage" || ENV === "development" ? (
									<>
										{store.isClientType(accountStore.account.type) ? (
											<BackgroundNavManagement />
										) : (
											<BackgroundNavManagementPro open />
										)}
									</>
								) : (
									<React.Fragment>
										<ListItem
											button
											component="a"
											href="/"
											key="home"
											onClick={toggleDrawer(anchor, false)}
											onKeyDown={toggleDrawer(anchor, false)}
										>
											<ListItemIcon className={classes.listIcon}>
												<HomeOutlinedIcon />
											</ListItemIcon>
											<ListItemText
												className={classes.listItem}
												disableTypography
												primary="Home"
											/>
										</ListItem>
										{store.isClientType(accountStore.account.type) && (
											<ListItem
												button
												component="a"
												href="/client/projects"
												key="projects"
												onClick={toggleDrawer(anchor, false)}
												onKeyDown={toggleDrawer(anchor, false)}
											>
												<ListItemIcon className={classes.listIcon}>
													<ListAltOutlinedIcon />
												</ListItemIcon>
												<ListItemText
													className={classes.listItem}
													disableTypography
													primary="Requests"
												/>
											</ListItem>
										)}
										{store.isClientType(accountStore.account.type) && (
											<ListItem
												button
												component="a"
												href="/client/inbox"
												key="inbox"
												onClick={toggleDrawer(anchor, false)}
												onKeyDown={toggleDrawer(anchor, false)}
											>
												<ListItemIcon className={classes.listIcon}>
													<MailOutlineOutlinedIcon />
												</ListItemIcon>
												<ListItemText
													className={classes.listItem}
													disableTypography
													primary="Inbox"
												/>

												{!!store.inboxCount && (
													<Badge
														badgeContent={store.inboxCount}
														color="primary"
														overlap="rectangular"
														classes={{
															anchorOriginTopRightRectangular:
																classes.listBadge,
														}}
													/>
												)}
											</ListItem>
										)}
										<ListItem
											button
											component="a"
											href={store.redirectToAccount(accountStore.account.type)}
											key="account"
											onClick={toggleDrawer(anchor, false)}
											onKeyDown={toggleDrawer(anchor, false)}
										>
											<ListItemIcon className={classes.listIcon}>
												<AccountCircleOutlinedIcon />
											</ListItemIcon>
											<ListItemText
												className={classes.listItem}
												disableTypography
												primary="Account"
											/>
										</ListItem>
										{store.isProviderType(accountStore.account.type) && (
											<ListItem
												button
												component="a"
												href="/pro"
												key="pro"
												onClick={toggleDrawer(anchor, false)}
												onKeyDown={toggleDrawer(anchor, false)}
											>
												<ListItemIcon className={classes.listIcon}>
													<DashboardIconOutlined />
												</ListItemIcon>
												<ListItemText
													className={classes.listItem}
													disableTypography
													primary={"Pro dashboard"}
												/>
											</ListItem>
										)}
										<ListItem
											button
											onClick={() => accountStore.logout()}
											key="sign_out"
											onKeyDown={toggleDrawer(anchor, false)}
										>
											<ListItemIcon className={classes.listIcon}>
												<ExitToAppOutlinedIcon />
											</ListItemIcon>
											<ListItemText
												className={classes.listItem}
												disableTypography
												primary="Sign out"
											/>
										</ListItem>
									</React.Fragment>
								)}
							</>
						) : (
							<React.Fragment>
								<ListItem
									button
									component="a"
									href="/"
									key="home"
									onClick={toggleDrawer(anchor, false)}
									onKeyDown={toggleDrawer(anchor, false)}
								>
									<ListItemIcon className={classes.listIcon}>
										<HomeOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.listItem}
										disableTypography
										primary="Home"
									/>
								</ListItem>

								<ListItem button key="explore" onClick={handleClick}>
									<ListItemIcon className={classes.listIcon}>
										<ExploreOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.listItem}
										disableTypography
										primary={"Explore"}
									/>
									{expand ? <ExpandLess /> : <ExpandMore />}
								</ListItem>

								<Collapse in={expand} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{quickLinkServices.map((quickLinkService) => (
											<ListItem
												dense
												button
												component="a"
												href={quickLinkService.link}
												key={shortid.generate()}
												onClick={toggleDrawer(anchor, false)}
												onKeyDown={toggleDrawer(anchor, false)}
											>
												<ListItemIcon
													className={classes.listIcon}
												></ListItemIcon>
												<ListItemText
													className={classes.listItem}
													disableTypography
													primary={quickLinkService.title}
												/>
											</ListItem>
										))}
									</List>
								</Collapse>
								<ListItem
									button
									component="a"
									href="/pro"
									key="pro_join"
									onClick={toggleDrawer(anchor, false)}
									onKeyDown={toggleDrawer(anchor, false)}
								>
									<ListItemIcon className={classes.listIcon}>
										<WorkOutlineOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.listItem}
										disableTypography
										primary={proJoinLanguage}
									/>
								</ListItem>

								<ListItem
									button
									component="a"
									href="/join"
									key="signup"
									onClick={toggleDrawer(anchor, false)}
									onKeyDown={toggleDrawer(anchor, false)}
								>
									<ListItemIcon className={classes.listIcon}>
										<AddCircleOutlineOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.listItem}
										disableTypography
										primary="Sign up"
									/>
								</ListItem>
								<ListItem
									button
									component="a"
									href="/login"
									key="login"
									onClick={toggleDrawer(anchor, false)}
									onKeyDown={toggleDrawer(anchor, false)}
								>
									<ListItemIcon className={classes.listIcon}>
										<AccountCircleOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										className={classes.listItem}
										disableTypography
										primary="Log in"
									/>
								</ListItem>
							</React.Fragment>
						)}
					</List>
				</div>
			</Drawer>
		</div>
	);
});

Header.defaultProps = {
	searchBar: true,
};

Header.propTypes = {
	children: PropTypes.node,
	searchBar: PropTypes.bool,
};

export default React.memo(Header);
