import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TrustedCareIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 41 41"}>
		<title>Trusted Care</title>
		<g fill="#00a1f2">
			<path
				d="M27.24 7.5l-.48.43a6.66 6.66 0 0 0-.43.48L9.99 24.75 20.5 35.26l16.34-16.34.47-.43.43-.47c2.48-2.93 2.34-7.32-.43-10.08-2.76-2.76-7.15-2.9-10.08-.43z"
				style={{ mixBlendMode: "multiply" }}
			/>
			<path
				d="M3.25 18.01l.43.48a6.66 6.66 0 0 0 .48.43L20.5 35.26l10.51-10.51L14.67 8.4l-.43-.47-.47-.43c-2.93-2.48-7.32-2.34-10.08.43-2.76 2.76-2.9 7.15-.43 10.08z"
				style={{ mixBlendMode: "multiply" }}
			/>
		</g>
	</SvgIcon>
);

TrustedCareIcon = memo(TrustedCareIcon);
TrustedCareIcon.displayName = "TrustedCareIcon";
TrustedCareIcon.muiName = "TrustedCareIcon";

export default TrustedCareIcon;
