import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let LawfulIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 500 500"}>
		<title>Lawful</title>

		<g>
			<polygon
				fill="#C79C69"
				points="282.01,250.11 282.01,205.4 21.79,205.4 21.79,176.82 337.13,176.82 337.13,132.1 21.79,132.1 
		21.79,21.79 478.21,21.79 478.21,478.21 250.97,478.21 250.97,277.93 206.26,277.93 206.26,478.21 173.52,478.21 173.52,277.93 
		128.8,277.93 128.8,478.21 96.06,478.21 96.06,277.93 51.35,277.93 51.35,478.21 21.79,478.21 21.79,250.11 	"
			/>
		</g>
	</SvgIcon>
);

LawfulIcon = memo(LawfulIcon);
LawfulIcon.displayName = "LawfulIcon";
LawfulIcon.muiName = "LawfulIcon";

export default LawfulIcon;
