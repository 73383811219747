import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let RVgoIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 512 512"}>
		<title>RVGO - RV rentals</title>
		<path
			d="m497 353h-42c-8.284 0-15 6.716-15 15l15 15h42c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
			fill="#1f00ba"
		/>
		<path
			d="M216 81h-81C60.561 81 0 141.561 0 216v103.262L216 294l40-121.5z"
			fill="#e6ebf0"
		/>
		<path
			d="M455 176c0-52.383-42.617-95-95-95H216v213l239-30z"
			fill="#c9d0d7"
		/>
		<path
			d="M144 264c-74.288 0-120.972 33.016-144 55.262V368c0 8.284 6.716 15 15 15h201l30-67-30-52h-72z"
			fill="#249cff"
		/>
		<path d="M455 264H216v119h239z" fill="#0027cc" />
		<path
			d="M368 169h-64c-8.284 0-15 6.716-15 15v128c0 8.284 6.716 15 15 15h64c8.284 0 15-6.716 15-15V184c0-8.284-6.716-15-15-15z"
			fill="#4db9ea"
		/>
		<path
			d="m185 137h-80c-8.284 0-15 6.716-15 15v56c0 8.284 6.716 15 15 15h80c8.284 0 15-6.716 15-15v-56c0-8.284-6.716-15-15-15z"
			fill="#7bcaf3"
		/>
		<path
			d="m168 368c0 26.51 21.49 48 48 48l20-48-20-48c-26.51 0-48 21.49-48 48z"
			fill="#505165"
		/>
		<path
			d="m216 320v96c26.51 0 48-21.49 48-48s-21.49-48-48-48z"
			fill="#3f383f"
		/>
		<path
			d="m183 368c0-18.196 14.804-33 33-33l10-15-10-15c-34.738 0-63 28.262-63 63s28.262 63 63 63l10-15-10-15c-18.196 0-33-14.804-33-33z"
			fill="#5f6075"
		/>
		<path
			d="m216 305v30c18.196 0 33 14.804 33 33s-14.804 33-33 33v30c34.738 0 63-28.262 63-63s-28.262-63-63-63z"
			fill="#505165"
		/>
	</SvgIcon>
);

RVgoIcon = memo(RVgoIcon);
RVgoIcon.displayName = "RVgoIcon";
RVgoIcon.muiName = "RVgoIcon";

export default RVgoIcon;
